<template>
  <div>
    <v-divider class="my-5" />
    <label-icon label="Fenômenos Tumorais" icon="bubble_chart" bold />
    <div v-if="hasTumoralPhenomena">
      <v-list two-line>
        <v-list-group
          v-for="(tp, indexTp) in addedTumoralPhenomena"
          :key="indexTp"
          class="elevation-1"
          @click="selectIndex(indexTp)"
        >
          <template v-slot:activator>
            <v-list-item class="py-3">
              <v-list-item-content>
                <v-list-item-title>
                  {{ tp.tumoralPhenomenon.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Zona de Resolução:
                  {{ tp.diagnoseResolutionZone.resolutionZone.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <div v-if="!!selected">
            <tracking-impaction-point-view-sides
              class="grey"
              :tracking-point="
                selected.diagnoseResolutionZone.resolutionZone.trackingPoint
              "
              :impaction-point="
                selected.diagnoseResolutionZone.resolutionZone.impactionPoint
              "
              :tracking-point-side="
                selected.diagnoseResolutionZone.trackingPointSide
              "
              :impaction-point-side="
                selected.diagnoseResolutionZone.impactionPointSide
              "
              :has-par-trauma="true"
              :par-trauma-side="selected.diagnoseResolutionZone.parTraumaSide"
            />

            <div class="d-flex flex-column grey lighten-3">
              <view-point
                label="Par trauma"
                :name="
                  selected.diagnoseResolutionZone.resolutionZone.impactionPoint
                    .name
                "
                :image="
                  selected.diagnoseResolutionZone.resolutionZone.impactionPoint
                    .image
                "
                :image-description="
                  selected.diagnoseResolutionZone.resolutionZone.impactionPoint
                    .imageDescription
                "
                :expand-image="true"
              />
              <div class="d-flex justify-center">
                <property-value
                  property="Lado(s) selecionado(s)"
                  :value="
                    selected.diagnoseResolutionZone.parTraumaSide
                      | formatPointSides
                  "
                />
              </div>
            </div>

            <div class="mx-5 my-7 grey--text text--darken-3 body-2">
              <text-with-editor
                :value="selected.diagnoseResolutionZone.affectedSpotParTrauma"
                label="Ponto afetado - Par Trauma"
                return-value="newValue"
                @save="changeAffectedSpotParTrauma"
              />
              <property-value
                property="Instruções"
                :value="selected.tumoralPhenomenon.instructions"
                class="pre-line"
              />
              <property-value
                property="Checou Associações Patogêncicas"
                :value="selected.checkedCategories | booleanToString"
              />
              <switch-editor
                :value="selected.hidePatient"
                label="Esconder ao paciente na diagnose"
                return-value="hidePatient"
                @save="changeProperties"
                @open="selectIndex(index)"
              />
              <switch-editor
                :value="selected.patientImpactAtHome"
                label="Recomendar ao paciente impactar em casa"
                return-value="patientImpactAtHome"
                @save="changeProperties"
                @open="selectIndex(index)"
              />

              <text-with-editor
                :value="selected.therapistComments"
                label="Comentários do terapeuta (não será exibido para o paciente)"
                return-value="newValue"
                @save="changeProperties"
              />

              <text-with-editor
                :value="selected.patientComments"
                label="Comentários ao paciente"
                return-value="newValue"
                @save="changeProperties"
              />
              <pares-simple-list :pares="relatedPares" />
            </div>
            <v-row justify="center" class="py-5">
              <v-btn dark color="red" @click="removeTumoralPhenomenon(indexTp)">
                <v-icon class="mr-2">delete</v-icon>
                Remover
              </v-btn>
            </v-row>
          </div>
        </v-list-group>
      </v-list>
    </div>
    <div v-else class="mt-5">Nenhum fênomeno tumoral adicionado.</div>
  </div>
</template>

<script>
import ParesSimpleList from "@/domains/therapy/shared/presentation/components/ParesSimpleList";
export default {
  components: {
    ParesSimpleList,
    TextWithEditor: () => import("@/components/shared/TextWithEditor"),
  },

  data: () => ({
    selectedIndex: -1,
    hasTumoralPhenomena: false,
    addedTumoralPhenomena: null,
    selected: null,
    diagnose: null,
    relatedPares: [],
  }),

  created() {
    this.init();
  },

  methods: {
    async init() {
      const hasSomeAdded = await this.$store.getters[
        "diagnosis/tumoralPhenomena/hasSomeAdded"
      ];
      const { added } = await this.$store.state.diagnosis.tumoralPhenomena;
      const selected = await this.$store.state.diagnosis.selected;
      this.hasTumoralPhenomena = hasSomeAdded;
      this.addedTumoralPhenomena = added;
      this.selected = selected;
      this.diagnose = await this.$store.getters["diagnosis/diagnose"];
    },
    async removeTumoralPhenomenon(index) {
      const response = await this.$store.dispatch("settings/openConfirm", {
        title: "Confirmar remoção?",
        message: "Essa ação não poderá ser desfeita",
      });
      if (response)
        this.$store.commit(
          "diagnosis/tumoralPhenomena/removeFromDiagnose",
          index
        );
    },

    changeProperties(value) {
      this.$store.commit("diagnosis/changePropertyTumoralPhenomena", {
        index: this.indexSelected,
        property: value,
      });
    },
    changeAffectedSpotParTrauma({ newValue }) {
      this.$store.commit(
        "diagnosis/tumoralPhenomena/changeAffectedSpotParTrauma",
        {
          index: this.selectedIndex,
          newValue,
        }
      );
    },
    selectIndex(index) {
      this.selectedIndex = index;
      const tumoralPhenomena = this.addedTumoralPhenomena[index];
      const ids = tumoralPhenomena.relatedPares;
      const pares = this.diagnose.pares
        .map((par) => par.par)
        .filter((par) => ids.includes(par.id));
      this.relatedPares = pares;
      this.selected = tumoralPhenomena;

      this.$store.dispatch("diagnosis/tumoralPhenomena/selectOne", index);
    },
  },
};
</script>

<style></style>
